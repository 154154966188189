import { forum, detailOne } from "../../js/path";

export default {
   data() {
      return {
         submitted: false,
         pageNo: null,
         table_header: [],
         getForumList: [],
         currentPage: 1,
         filter: null,
         filterOn: [],
         sortBy: "id",
         sortDesc: true,
         activeTab: "all",
         tableData: [],
         key: 0,
         params: "",
         form: {
            name: '',
            description: '',
            brand_forum_id: ''
         },
         image: '',
         image_url: '',
         edit: {
            image_url: null
         },
         fields: [{
            key: "id",
            label: "ID"
         },
         {  
            key: "name",
            label: "Title",
            class: "w-300"
         },
         {
            key: "description",
            label: "Product LBL",
            class: "w-300"
         },
         {
            key: "brand_forum_name",
            label: "Forum",
            class: "w-300"
         },
         {
            key: "image",
            label: "Thumbnail",
            class: "w-300"
         },
         {
            key: "edit",
         },
         {
            key: "delete",
         }]
      }
   },
   methods: {
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
      },
      searchFor() {
         if (this.filter.length > 1) {
           this.fetchData(this.activeTab); 
         } else if (this.filter.length == 0) {
           this.fetchData(this.activeTab);
         }
       },
       filterPage() {
         if (this.pageNo.length > 0) {
           this.params = `&page=${this.pageNo}`;
           this.fetchData(this.activeTab);
           this.currentPage = this.pageNo;
         }
       },
       search(event) {
         if (this.filter.length > 1) {
           if (event.keyCode == 13) {
             this.fetchData(this.activeTab);
           }
         } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      async fetchForum() {
         const url = forum.fetchAllActiveForum;
         const data = await this.getRequest(url);
         if (data.status) {
           this.getForumList = data.response.data;   
         }
      },
      readFile(e, txt) {
         if (txt == "image") {
            this.image = e.target.files[0];
            if (this.image.size > 2 * 1000 * 1000) {
              this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: "Uploaded File is More than 2MB"
              });
              this.$refs.image.reset();
              this.image_url = '';
              this.edit.image_url = '';
              this.image = '';
              return false;
            } else {
              this.image_url = URL.createObjectURL(this.image);
              this.edit.image_url = '';
            }
            return true;
         }
      },
      async fetchData(txt) {
         let url = null;
         if (txt == "active") {
           this.activeTab = txt;
           if (this.filter != null && this.filter != "") {
             url =
               detailOne.getDetailOne + "?filter=" + txt + "&search=" + this.filter;
           } else {
             url = detailOne.getDetailOne + "?filter=" + txt;
           }
         } else if (txt == "trash") {
           this.activeTab = txt;
           if (this.filter != null && this.filter != "") {
             url =
               detailOne.getDetailOne + "?filter=" + txt + "&search=" + this.filter;
           } else {
             url = detailOne.getDetailOne + "?filter=" + txt;
           }
         } else if (txt == "all") {
           this.activeTab = txt;
           if (this.filter != null && this.filter != "") {
             url =
               detailOne.getDetailOne + "?filter=" + txt + "&search=" + this.filter;
           } else {
             url = detailOne.getDetailOne + "?filter=" + txt;
           }
         }
         url += this.params;
         this.$store.commit("loader/updateStatus", true);
         try {
           const data = await this.getRequest(url);
           if (data.status) {
             const responseData = data.response;
             this.tableData = responseData;
           }
         } catch (err) {
           console.log(err);
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
         this.key += 1;
      },
      async deleteDetailOne(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = detailOne.getDetailOne + "/" + id;
           const data = await this.postRequest(url, {
             _method: "DELETE",
           });
           if (data.status) {
             this.tableData.data.splice(index, 1);
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "success",
               title: data.message,
             });
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async restoreDetailOne(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = detailOne.getDetailOne + "/" + id;
           const data = await this.postRequest(url, {
             _method: "DELETE",
           });
           if (data.status) {
             this.tableData.data.splice(index, 1);
             this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "success",
               title: data.message,
             });
           }
         } catch (err) {
           this.$store.commit("toast/updateStatus", {
             status: true,
             icon: "error",
             title: err.data ? err.data.message : "Please try again!",
           });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async fetchDetailOne(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            const url = detailOne.getDetailOne + "/" + id;
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               if (responseData.name != null && responseData.name != '') {
                  this.form.name = responseData.name;
               } 
               if (responseData.description != null && responseData.description != '') {
                  this.form.description = responseData.description;
               }
               if (responseData.brand_forum_id != null && responseData.brand_forum_id != '') {
                  this.form.brand_forum_id = responseData.brand_forum_id;
               }
               if (responseData.image != null || responseData.image != '') {
                  this.edit.image_url = responseData.image;
                  this.image = responseData.image;
               }
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async submitData() {
         try {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
               window.scrollTo({top: 0, behavior: "smooth"});
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "error",
                  title: "Please Fill The Required Fields"
               });
               return false;
            }
            this.$store.commit("loader/updateStatus", true);
            let dataAppend = new FormData();
            for (var key in this.form) {
               if (key != "image") {
                  dataAppend.append(key, this.form[key]);
               }
            }
            if (this.image != '') {
               dataAppend.append("image", this.image);
            }
            let url = detailOne.getDetailOne;
            if (this.$route.name == 'edit-detail-one') {
               url = detailOne.getDetailOne + "/" + this.$route.params.id;
               dataAppend.append('_method', 'put');
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
               this.$router.push("/detailOne");
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data.message ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      tabActive() {
         if (this.activeTab == "trash") {
           if (this.table_header.includes("delete")) {
             let index = this.fields.findIndex((item) => item.key == "delete");
             this.fields[index] = {
               key: "restore",
             };
             let table_index = this.table_header.findIndex((item) => item == "delete");
             this.table_header[table_index] = "restore";
   
           } else {
             this.table_header.push("restore");
             this.fields.push({
               key: "restore",
             });
           }
   
           if (!this.can("restore-detail1")) {
             let index = this.fields.findIndex((item) => item.key == "restore");
             if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                 (item) => item == "restore"
               );
               this.table_header.splice(table_index, 1);
             }
           }
   
           let index = this.fields.findIndex((item) => item.key == "edit");
           if (index !== -1) {
             this.fields.splice(index, 1);
             let table_index = this.table_header.findIndex(
               (item) => item == "edit"
             );
             this.table_header.splice(table_index, 1);
           }
         } else {
           if (this.table_header.includes("restore")) {
             if (!this.can("edit-detail1")) {
               this.fields[5] = {
                 key: "delete",
               };
             } else {
               this.fields[6] = {
                 key: "delete",
               };
             }
   
             let table_index = this.table_header.findIndex((item) => item == "restore");
             this.table_header[table_index] = "delete";
           }
   
           if (!this.can("edit-detail1")) {
             let index = this.fields.findIndex((item) => item.key == "edit");
             if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                 (item) => item == "edit"
               );
               this.table_header.splice(table_index, 1);
             }
           } else {
             if (!this.table_header.includes("edit")) {
               this.table_header.push("edit");
               this.fields[5] = {
                 key: "edit",
               };
             }
           }
   
           if (!this.can("delete-detail1")) {
             let index = this.fields.findIndex((item) => item.key == "delete");
             if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                 (item) => item == "delete"
               );
               this.table_header.splice(table_index, 1);
             }
           } else {
             if (!this.table_header.includes("delete")) {
               this.table_header.push("delete");
               this.fields[6] = {
                 key: "delete",
               };
             }
           }
         }
      },
   },
   watch: {
      currentPage: {
         handler: function (value) {
           this.params = `&page=${value}`;
           this.fetchData(this.activeTab);
           this.pageNo = this.currentPage;
         },
      },
      activeTab(v) {
         if (v) this.tabActive();
      },
   },
   mounted() {
      if (this.$route.name == 'add-detail-one' || this.$route.name == 'edit-detail-one') { 
         this.fetchForum();
         if (this.$route.name == 'edit-detail-one') {
            this.fetchDetailOne(this.$route.params.id);
         }
      } else if (this.$route.name == 'detail-one') {
         this.fetchData(this.activeTab);
         this.fields.map((item) => {
            this.table_header.push(item.key);
         });
         this.tabActive();
      }
   }
}